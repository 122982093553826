<template>
  <div class="app" :class="this.level == 9 && 'is-admin'">
    <AppHeader fixed>
      <b-link class="header-logo" to="/" aria-label="런던문자 로고">
        <i class="navbar-brand-full mx-4 logo"></i>
      </b-link>
      <div class="nav-container">
        <b-navbar-nav v-if="this.user != null" class="fl mr-4">
          <b-nav-item v-if="this.level == 9" @click="goTo('/admin/users')">회원관리</b-nav-item>
          <b-nav-item v-if="this.level == 9" @click="goTo('/admin/statics')">통계</b-nav-item>
          <b-nav-item v-if="this.level == 6 || this.level == 9" @click="goTo('/admin/distributors')">
            총판관리
          </b-nav-item>
          <b-nav-item v-if="this.level == 9" @click="goTo('/payment/history')">충전관리</b-nav-item>
          <b-nav-item v-if="this.level == 9" @click="goTo('/admin/recommendCodes')">코드관리</b-nav-item>
          <!-- <b-nav-item v-if="this.level == 9" to="/admin/banList">차단관리</b-nav-item> -->
          <b-nav-item @click="goTo('/smsSend')">발송</b-nav-item>
          <b-nav-item @click="goTo('/smsSendTest')">3사테스트</b-nav-item>
          <b-nav-item @click="goTo('/smsRequests')">결과</b-nav-item>
          <b-nav-item @click="goTo('/payment')" exact>충전</b-nav-item>
          <b-nav-item @click="goTo('/contacts')">주소록</b-nav-item>
          <b-nav-item @click="goTo('/dumps')">결과추출</b-nav-item>
          <b-nav-item v-show="isShowBoard" @click="goTo('/board')" exact>1:1문의</b-nav-item>
          <b-nav-item v-if="this.level == 9" @click="goTo('/admin/healthCheck')">서버상태</b-nav-item>
          <b-nav-item @click="goTo('/mypage')" exact>마이페이지</b-nav-item>
          <b-nav-item @click.prevent="logout">로그아웃</b-nav-item>
        </b-navbar-nav>
        <b-navbar-nav v-else class="fl mr-4">
          <b-nav-item @click="goTo('/login')">로그인</b-nav-item>
          <b-nav-item @click="goTo('/register')" exact>회원가입</b-nav-item>
        </b-navbar-nav>
      </div>
      <div class="menu-btn" @click="toggleNaviArea"><i class="icon"></i></div>
    </AppHeader>
    <div class="app-body">
      <main class="main pt-4 pb-4">
        <div class="container-fluid">
          <router-view :key="forceReload"></router-view>
        </div>
      </main>
      <img class="mobile-contact-desc" src="/img/chat_desc.webp" v-show="isMobile" alt="채팅문의 클릭 유도 문구" />
      <img class="mobile-contact-icon" src="/img/chat_icon.webp" @click.prevent="goToContact" v-show="isMobile"
        alt="채팅문의 아이콘" />
      <TheFooter>
        <div>
          <p class="font-weight-bold">
            &copy; 2015-2024 런던문자. ALL RIGHTS RESERVED.
          </p>
        </div>
      </TheFooter>
    </div>
  </div>
</template>

<script>
import { Header as AppHeader, Footer as TheFooter } from '@coreui/vue'

export default {
  name: 'full',
  components: {
    AppHeader,
    TheFooter,
  },
  data() {
    return {
      statusTimer: null,
      scrollTimeout: null,
      isMobile: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent),
      isShowNavi: false,
      forceReload: 0
    }
  },
  mounted() {
    document.querySelectorAll('.nav-item > a').forEach(function (item) {

      if (location.pathname == item.getAttribute('href')) {
        item.classList.add('active');
      }
    })

    const token = this.$cookie.get('londonsms_token')
    if (token) {
      this.getUserInfo()
    }

    this.addEvent()
    this.checkRoute();
  },
  methods: {
    goTo(path) {
      this.forceReload += 1
      this.$router.push({ path })
    },
    goToContact() {
      var suffix = ''
      if (this.user) {
        var user = this.user
        suffix = user ? `?username=${user.username}&email=${user.email}&key=${user.chatKey}` : ''
      }
      window.open('/contact.html' + suffix)
    },
    toggleNaviArea() {
      this.isShowNavi = !this.isShowNavi
      if (this.isShowNavi) {
        document.body.classList.add('show-navi')
      }
      else {
        document.body.classList.remove('show-navi')
      }
    },
    checkNaviBarTransparent(isEnter) {
      if (location.pathname !== '/home') {
        return
      }

      const appHeaderEl = document.getElementsByClassName('app-header')[0]
      const appBodyEl = document.getElementsByClassName('app-body')[0]

      clearTimeout(this.scrollTimeout)
      this.scrollTimeout = setTimeout(function () {
        if (appBodyEl.scrollTop > 100) {
          return
        }

        if (isEnter) {
          appHeaderEl.classList.remove('transparent')
        } else {
          appHeaderEl.classList.add('transparent')
        }
      }, 50)
    },
    addEvent() {
      const appHeaderEl = document.getElementsByClassName('app-header')[0]
      const t = this

      appHeaderEl.addEventListener('mouseenter', function () {
        t.checkNaviBarTransparent(true)
      })

      appHeaderEl.addEventListener('mouseleave', function () {
        t.checkNaviBarTransparent(false)
      })
    },
    startStatusCheckInterval() {
      clearInterval(this.statusTimer)
      this.getStatus()

      const t = this
      this.statusTimer = setInterval(() => {
        t.getStatus()
      }, 60 * 10000)
    },
    checkRoute() {
      document.body.classList.remove('show-navi')

      let viewPortContent;
      if (location.pathname === '/') {
        document.body.classList.add('is-home')
        this.isShowNavi = false
        viewPortContent = 'width=device-width, initial-scale=1, user-scalable=1';
      }
      else {
        document.body.classList.remove('is-home')
        viewPortContent = 'user-scalable=1, width=1240';
      }

      document
        .getElementsByTagName('meta')
        .namedItem('viewport')
        .setAttribute('content', viewPortContent)
    }
  },
  destroyed() {
    clearInterval(this.statusTimer)
  },
  computed: {
    name() {
      return this.$route.name
    },
    user() {
      if (this.$store.state.user) {
        this.startStatusCheckInterval();
      }

      return this.$store.state.user
    },
    level() {
      if (!this.$store.state.user) {
        return 0
      }
      return this.$store.state.user.level
    },
    list() {
      return this.$route.matched.filter(route => route.name || route.meta.label)
    },

    isShowBoard() {
      if (!this.$store.state.user) {
        return -1
      }

      const recommendCode = this.$store.state.user.recommendCode
      const index = ['wa77', 'wa88', 'wa99', 'wa20', 'qwe', 'asd', 'zxc_0'].indexOf(recommendCode)
      return index == -1
    },
  },
  watch: {
    $route() {
      this.checkRoute();
    },
    user() { },
    level() { },
  },
}
</script>
<style lang="scss">
.header-logo {
  display: flex;
  flex-flow: column;
  text-decoration: none !important;
}

.logo2 {
  display: none;
}

.header-logo>span:first-of-type {
  color: #009bfa;
  font-size: 27px;
  line-height: 28px;
  font-weight: 700;
}

.header-logo>span:nth-of-type(2) {
  color: #009bfa;
  display: block;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  letter-spacing: -2px;
}

.app-footer {
  flex: 0 0 80px;
}

.app-footer>div>p {
  margin-top: 8px;
  font-size: 13px;
  line-height: 13px;
  margin-bottom: 0;
  color: #666;
}

.mobile-contact-icon {
  position: fixed;
  right: 0;
  bottom: 0;
  transform: scale(1.2);
}

.mobile-contact-desc {
  position: fixed;
  display: none;
}

.register-mode .mobile-contact-desc {
  display: block !important;
  right: 110px;
  bottom: 130px;
}

.register-mode.mobile .mobile-contact-desc {
  right: 125px;
  bottom: 200px;
}
</style>
